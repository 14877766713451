import React, {useEffect, useState} from 'react'
import { Card, Col, Row, notification } from 'antd'
import payment_sucessful from '../../assets/images/restaurant/payment_successful.png'
import {useNavigate, useParams} from 'react-router-dom'
import { CheckCircleFilled } from '@ant-design/icons'
import FooterDisplay from '../../components/footer'
import NumberFormat from "react-number-format";
import {_get_single_restaurant_order_details} from "../../common/axios_services";
import moment from "moment";



const CheckoutSuccessful = () => {
    const navigate = useNavigate();
    const {id: orderId} = useParams();
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [order, setOrder] = useState({});
    const [orderitems, setOrderItems] = useState([]);
    const [trackId, setTrackId] = useState('');
    const [orderHistory, setOrderHistory] = useState({})
    const [restaurantName, setRestaurantName] = useState('');



    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
        });
    };


    const getOrderHist = async () => {
        try {
            setSpinnerLoading(true);
            const get_hist = await _get_single_restaurant_order_details(orderId);
            const responseData = get_hist.data.data;
            setOrderHistory(get_hist.data.data)

            setOrder(responseData.order);
            setOrderItems(responseData.items);
            setTrackId(get_hist.data.data.order.track_id);
            setRestaurantName(get_hist.data.data.restaurant.name);
        }
        catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        } finally {
            setSpinnerLoading(false);
        }
    }

    useEffect( () => {
        getOrderHist();
    }, [] )


  return (
      <div>
          <div className="py-20">
              <Row gutter={{lg: 44}} justify={'center'}>
                  <Col xs={22} lg={16}>
                      <div className="flex flex-col justify-center items-center ">
                          <h2 className='lg:text-6xl text-4xl'>Payment successful!</h2>
                          <h2 className='lg:text-6xl text-4xl'>Your food is on the way</h2>
                          <div className="py-5">
                              <img src={payment_sucessful} className='w-full h-full' alt="payment successfull"/>
                          </div>
                      </div>

                  </Col>
                  <Col xs={22} lg={8}>
                      <Card bordered={false}
                            className="flex-col px-10 py-20 lg:mt-28 items-center bg-[#f5fff5] rounded-[32px] border-2 border-black">
                          <div className="divide-y-2">
                              <div className="rest_header flex justify-between">
                                  <h2 className='text-[16px] text-[#959595]'>Order Reference Code</h2>
                                  <div className="flex gap-3">
                                      <p className='text-[16px] font-semibold'>#{trackId}</p>
                                  </div>
                              </div>
                              <div className="order_item">
                                  <p className='text-[#959595] text-[15px] '>Item</p>
                                  {orderitems.map((item) => (
                                      <>
                                          <div className="flex mt-3 gap-5" key={item.id}>
                                              {
                                                  item.product_type === "menu" ? (
                                                          <>
                                                              <p className="text-3xl font-bold ">{item.quantity} x</p>
                                                              <p className="text-2xl font-bold text-wrap">{item.menu.name}</p>
                                                          </>
                                                      ) :
                                                      item.product_type === "extra" ? (
                                                          <>
                                                              <p className="text-3xl font-bold ">{item.quantity} x</p>
                                                              <p className="text-2xl font-bold text-wrap">{item?.extra?.name}</p>
                                                          </>
                                                      ) : ""
                                              }
                                          </div>
                                      </>
                                  ))}
                              </div>
                              {/*<div className="order_item ">*/}
                              {/*    <p className='text-[#959595] text-[15px] '>Item</p>*/}
                              {/*    <div className="flex mt-3 gap-5">*/}
                              {/*        <p className="text-3xl font-bold ">2x</p>*/}
                              {/*        <p className="text-2xl font-bold text-wrap">Fried Rice & Chicken - 1 portion and half chicken</p>*/}
                              {/*    </div>*/}
                              {/*</div>*/}
                              <div className="">
                                  <div className="flex flex-col py-2">
                                      <p className='text-[15px] text-[#959595] '>Restaurant</p>
                                      <p className='text-2xl'>{restaurantName}</p>
                                  </div>
                              </div>
                              {/*<div className="">*/}
                              {/*    <div className="flex flex-col py-2">*/}
                              {/*        <p className='text-[15px] text-[#959595] '>Delivery to</p>*/}
                              {/*        <p className='text-2xl'>John Doe</p>*/}
                              {/*    </div>*/}
                              {/*</div>*/}
                              {/*<div className="">*/}
                              {/*    <div className="flex flex-col py-2">*/}
                              {/*        <p className='text-[15px] text-[#959595] '>Contact</p>*/}
                              {/*        <p className='text-2xl'>John@gmail.com</p>*/}
                              {/*        <p>+234 700 000 0000</p>*/}
                              {/*    </div>*/}
                              {/*</div>*/}
                              <div className="">
                                  <div className="flex flex-col py-2">
                                      <p className='text-[15px] text-[#959595] '>Address</p>
                                      <p className='text-2xl'>{orderHistory.delivery_address}</p>
                                  </div>
                              </div>
                              <div className="">
                                  <div className="flex flex-col py-2">
                                      <p className='text-[15px] text-[#959595] '>Date</p>
                                      <p className='text-2xl'>
                                          {moment(order.created_at).format('Do MMMM, YYYY | hA')}
                                      </p>
                                  </div>
                              </div>
                              <div className="mt-5 flex flex-col mx-auto">
                                  <div
                                      className="bg-[#44843F] flex items-center justify-between w-full text-white rounded-3xl border-2 border-black px-6  py-3">
                                      <div className='flex flex-col'>
                                          <p>
                                              <NumberFormat
                                                  value={order.subtotal}
                                                  displayType={'text'}
                                                  thousandSeparator={true}
                                                  prefix={'₦'}
                                              />
                                          </p>
                                          <p>Payment received</p>
                                      </div>
                                      <CheckCircleFilled style={{fontSize: '44px'}}/>
                                  </div>
                                  <div className='flex mt-5 mx-auto cursor-pointer'
                                       onClick={() => navigate(`/restaurant/${orderId}/track_order`)}>
                                      <p className=' underline text-[15px] text-[#44843F] '>Track Delivery</p>
                                  </div>
                              </div>


                          </div>

                      </Card>
                  </Col>
              </Row>
          </div>
          <FooterDisplay/>
      </div>
  )
}

export default CheckoutSuccessful