import "../../../assets/css/mini.css";
import React, {useEffect, useState} from "react";
import {Input, notification, Drawer, Spin, Collapse, Pagination, Button, Tag} from "antd";
import 'swiper/css';
import {Link, useNavigate} from 'react-router-dom';
import {LoadingOutlined} from "@ant-design/icons";
import {ReactComponent as HelpIcon} from "../../../assets/images/icons/help-circle.svg";
import {
    _get_all_restaurant_orders
} from '../../../common/axios_services';
import EmptyGIF from "../../../assets/images/illustrations/no-entry.gif";
import FooterDisplay from "../../../components/footer";
import {useSelector} from "react-redux";
import TopNav from "../../../components/top-nav";




const OrderHistory = () => {
    const Navigate = useNavigate();
    const userProfile = useSelector(state => state.userProfile.dataFetched);

    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message
        });
    };
    const [currentHistDisp, setCurrentHistDisp] = useState("wallet");
    const majorSpinnerIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;
    const [majorPageSpinner, setMajorPageSpinner] = useState(true);
    const [page, setPage] = useState(1);
    const navigate = useNavigate()
    const params = new URLSearchParams(window?.location?.search);
    const [order_his, setOrder_his] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [per_page, setPer_page] = useState(10)
    const [total_trans, setTotal_trans] = useState(0)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);





    const allTransOrders = async () => {
        try {
            setMajorPageSpinner(true)
            const allOrders = await _get_all_restaurant_orders();
            if (allOrders?.data?.data) {
                setOrder_his(allOrders.data.data.data);
                setTotal_trans(allOrders.data.data.meta.total);
            } else {
                setOrder_his([]);
            }
        } catch (err) {
            console.error("Error loading orders:", err);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate("/signin?redir=review");
                    localStorage.removeItem('konnect_token');
                    openNotificationWithIcon('error', 'Session expired. Please sign in again.');
                } else {
                    openNotificationWithIcon('error', err.response.data.message);
                }
            } else {
                openNotificationWithIcon('error', err.message);
            }
        } finally {
            setMajorPageSpinner(false);
        }
    };



    useEffect(() => {
        allTransOrders();
    }, [per_page, page])

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPer_page(pageSize);
    }

    // ACTION VIEW
    const handleViewClick = (record) => {
        setSelectedOrder(record);
        setIsModalVisible(true);
    };



    return (
        <div className="wise9ja-content">
            <div>
                <Spin indicator={majorSpinnerIcon} spinning={majorPageSpinner}>
                    <div className="homepage wise9ja-disp">
                        <div className="homepage-history-header">
                            <TopNav title="Your order history"/>
                        </div>
                        <div className="hero-sect mt_2">
                        </div>
                        <div className="content-display-content">
                            <div className="contain">
                                <div className="wise9ja-action-bar">

                                </div>
                                <div className="mt history-data-detail">
                                    <div className="contai">
                                        {
                                            !majorPageSpinner ? (
                                                <div className="mobile topup-data">
                                                    <Collapse defaultActiveKey={[0]}>
                                                        {order_his.map((order, index) => (
                                                            <Collapse.Panel
                                                                showArrow={false}
                                                                key={index}
                                                                header={
                                                                    <p style={{display: 'inline'}}>
                                                                        Ordered
                                                                        on {new Date(order.created_at).toLocaleString()}
                                                                    </p>
                                                                }
                                                            >
                                                                <div>
                                                                    <ul className="mobile-list-flex">
                                                                        <li>
                                                                            <span>Date: </span>
                                                                            <span>{order.created_at}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span>Ref Code: </span>
                                                                            <span>#{order.track_id}</span>
                                                                        </li>
                                                                        <li>
                                                                            <span>Item: </span>
                                                                            <span>
                                                                                {Array.isArray(order.item)
                                                                                    ? order.item
                                                                                        .map((item) => item.quantity || 0)
                                                                                        .reduce((a, b) => a + b, 0)
                                                                                : 0}
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            <span>Status: </span>
                                                                            <span>
                                                                                {(order.status === "accepted" || order.status === "ready" || order.status === "picked") ? (
                                                                                    <Tag
                                                                                        color="green">{order.status}</Tag>
                                                                                ) : order.deliveryStatus === "canceled" ? (
                                                                                    <Tag
                                                                                        color="red">{order.status}</Tag>
                                                                                ) : (
                                                                                    <Tag
                                                                                        color="orange">{order.status}</Tag>
                                                                                )}
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="mt-3">
                                                                        <Button onClick={() => handleViewClick(order)} type="link">
                                                                            View Order
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </Collapse.Panel>
                                                        ))}
                                                    </Collapse>
                                                    <div style={{marginTop: "2%"}}></div>
                                                    <Pagination
                                                        showSizeChanger
                                                        current={page}
                                                        total={total_trans}
                                                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} orders`}
                                                        defaultPageSize={per_page}
                                                        onChange={change_page}
                                                    />
                                                </div>
                                                ) :
                                                ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
            <FooterDisplay/>

            <Drawer
                title="Order Details"
                placement="bottom"
                onClose={() => setIsModalVisible(false)}
                open={isModalVisible}
                className={"px-8 h-[80vh] bg-[red] "}
                width={720}
                height="100%"
            >
                {selectedOrder && (
                    <div>
                        <p>
                            <strong>Date:</strong>{' '}
                            {new Date(selectedOrder.created_at).toLocaleString()}
                        </p>
                        <p>
                            <strong>Ref Code:</strong> #{selectedOrder.track_id}
                        </p>
                        {
                            (selectedOrder.status === 'accepted' || selectedOrder.status === 'ready') && (
                                <p>
                                    <strong>Your Pickup Code:</strong> {selectedOrder.confirmationPin}
                                </p>
                            )
                        }

                        <p>
                            <strong>Restaurant:</strong>{' '}
                            {selectedOrder.restaurant.name}
                        </p>
                        <p>
                            <strong>Address:</strong> {selectedOrder.restaurant.address || 'N/A'}
                        </p>

                        <p>
                            <strong>Order Status: </strong>
                            {
                                selectedOrder.status === 'accepted' ? (
                                        <span>Order Accepted</span>
                                    ) :
                                    selectedOrder.status === 'ready' ? (
                                            <span> Order ready for pick-up</span>
                                        ) :
                                        selectedOrder.status === 'picked' ? (
                                            <span> Order picked up</span>
                                        ) : selectedOrder.status
                            }
                        </p>

                        <p>
                            <strong>Items Bought:</strong>
                            <ul>
                                {selectedOrder.item.map((item, index) => (
                                    <li key={index}>
                                        {
                                            item.product_type === "menu" ? (
                                                    <p>Menu: {item.quantity} of {item.menu.name}</p>
                                                ) :
                                                item.product_type === "extra" ? (
                                                    <p> Extra: {item.quantity} of {item.extra.name}</p>
                                                ) : ""
                                        }

                                    </li>
                                ))}
                            </ul>
                        </p>
                    </div>
                )}
            </Drawer>

        </div>
    )
}

export default OrderHistory;