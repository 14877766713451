import "./assets/css/index.css";

import React from "react";
import { Provider } from 'react-redux';
import store from './store';

import { BrowserRouter, Route, Routes } from "react-router-dom";

import AllAppRoutes from "./common/routes";

import Homepage from "./page/home";
import CategoriesPage from "./page/category";
import CartPage from "./page/cart";
import ProductDetailPage from "./page/detail";
import WelcomePage from "./page/welcome";
import FirstBlockPage from "./page/first-block";
import SignInPage from "./page/auth/signin";
import SignUpPage from "./page/auth/signup";
import ProfileSettings from "./page/profile/settings/settings";
import Wise9jaPage from "./page/wise9ja";
import HistoryPage from "./page/profile/history";
import DiscoverPage from "./page/discover";
import NotificationsPage from "./page/profile/notifications";
import OrdersPage from "./page/profile/orders";
import WalletPage from "./page/wallet";
import FundWalletPage from "./page/profile/actions/fund-wallet";
import ActionStatusPage from "./page/profile/actions/action-status";
import StartEarningPage from "./page/profile/actions/wise";
import Wise9jaHistoryPage from "./page/profile/history/wise9ja-history";
import WalletHistoryPage from "./page/profile/history/wallet-history";
import SingleHistoryPage from "./page/profile/actions/single-history";
import ContactUsPage from "./page/contact";
import PartnerPage from "./page/partner";
import TermsPage from "./page/terms";
import PrivacyPage from "./page/privacy";
import HowWise9jaWorks from "./page/wise9ja-how";
import VerificationPage from "./page/auth/verification";
import SalesReportPage from "./page/sales-report";
import HowReferralWorksPage from "./page/referral_how";
import ReferralPage from "./page/profile/settings/referral";
import E_IDPage from "./page/profile/settings/e-id";
import AssessmentPage from "./page/profile/assessment";
import HistoryDetailPage from "./page/profile/history-detail";
import SendResetMail from "./page/auth/reset";
import VerifyPasswordResetOtp from "./page/auth/verify_reset_mail";
import ResetPasswordPage from "./page/auth/reset_password";
import TransferToFriend from "./page/profile/actions/friend";
import TransferBetweenAccounts from "./page/profile/actions/transfer";
import WithdrawToBank from "./page/profile/actions//bank";

import ProfileBasicSettings from "./page/profile/settings/update/basic-settings";
import UpdatePasswordPage from "./page/profile/settings/update/password";
import UpdateTransactionCode from "./page/profile/settings/update/trans_code";
import UpdateLoginPin from "./page/profile/settings/update/login_pin";
import CheckOutPage from "./page/checkout";
import PayBills from "./page/profile/actions/bills";
import SignupSuccessfulPage from "./page/auth/signupsuccessful";
import CompleteKYCPage from "./page/auth/kyc";
import UpdateProfilePic from "./page/profile/settings/update/pic";
import Shop from "./page/shop";
import Restaurant from "./page/restaurant/restaurant";
import SinglePage from "./page/restaurant/SinglePage";
import ReviewCheckout from "./page/restaurant/ReviewCheckout";
import CheckoutSuccessful from "./page/restaurant/CheckoutSuccessful";
import TrackOrder from "./page/restaurant/TrackOrder";
import JoinUsPage from "./page/profile/settings/join";
import UpdateTransPin from "./page/profile/settings/update/trans_code_two";
import RestaurantCartPage from "./page/restaurant/RestaurantCartPage";
import ResetPin from "./page/auth/reset_pin";
import UpdateEmailPage from "./page/profile/settings/update/email";
import OrderHistory from "./page/profile/settings/view-orders";

const AppRoutes = () => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<WelcomePage />} />
                    <Route path="/welcome" element={<WelcomePage />} />
                    <Route path="/signin" element={<SignInPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path={AllAppRoutes.successful_signup} element={<SignupSuccessfulPage />} />
                    <Route path="/reset-mail" element={<SendResetMail />} />
                    <Route path="/reset-pin" element={<ResetPin />} />
                    <Route path={AllAppRoutes.complete_kyc} element={<CompleteKYCPage />} />
                    <Route path="/verify-passcode" element={<VerifyPasswordResetOtp />} />
                    <Route path="/reset-password/:id/:token" element={<ResetPasswordPage />} />

                    <Route path={AllAppRoutes.verify_email} element={<VerificationPage />} />
                    <Route path={AllAppRoutes.how_wise9ja_works} element={<HowWise9jaWorks />} />
                    <Route path={AllAppRoutes.contact} element={<ContactUsPage />} />
                    <Route path={AllAppRoutes.partner} element={<PartnerPage />} />
                    <Route path={AllAppRoutes.terms} element={<TermsPage />} />
                    <Route path={AllAppRoutes.privacy} element={<PrivacyPage />} />
                    <Route path={AllAppRoutes.pay_bills} element={<PayBills />} />
                    <Route path="/home" element={<Homepage />} />
                    <Route path="/history" element={<HistoryPage />} />
                    {/* <Route path="/history" element={<HistoryPage />} /> */}

                    <Route path="/notifications" element={<NotificationsPage />} />
                    <Route path="/wise9ja" element={<Wise9jaPage />} />
                    <Route path="/discover" element={<DiscoverPage />} />
                    <Route path="/categories" element={<CategoriesPage />} />
                    <Route path="/cart" element={<CartPage />} />
                    <Route path="/detail" element={<ProductDetailPage />} />

                    <Route path="/orders" element={<OrdersPage />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/restaurant" element={<Restaurant />} />
                    <Route path="/restaurant/:id" exact element={<SinglePage />} />
                    <Route path="/restaurant/:id/restaurant_cart" exact element={<RestaurantCartPage />} />
                    <Route path="/restaurant/:id/checkout" exact element={<ReviewCheckout />} />
                    <Route path="/restaurant/:id/checkout_successful" exact element={<CheckoutSuccessful />} />
                    <Route path="/restaurant/:id/track_order" exact element={<TrackOrder />} />
                    <Route path="/join-us" element={<JoinUsPage />} />

                    <Route path="/settings" element={<ProfileSettings />} />
                    <Route path={AllAppRoutes.action_status_page} element={<ActionStatusPage />} />

                    <Route path={AllAppRoutes.basic_profile} element={<ProfileBasicSettings />} />
                    <Route path={AllAppRoutes.update_picture} element={<UpdateProfilePic />} />
                    <Route path={AllAppRoutes.update_password} element={<UpdatePasswordPage />} />
                    <Route path={AllAppRoutes.update_email} element={<UpdateEmailPage />} />
                    <Route path={AllAppRoutes.update_transaction_code} element={<UpdateTransactionCode />} />
                    <Route path={AllAppRoutes.update_trans_pin} element={<UpdateTransPin />} />
                    <Route path={AllAppRoutes.update_login_pin} element={<UpdateLoginPin />} />
                    <Route path={AllAppRoutes.view_order_history} element={<OrderHistory />} />

                    <Route path="/history-detail/:id" element={<HistoryDetailPage />} />
                    <Route path="/profile/settings" element={<ProfileBasicSettings />} />

                    <Route path={AllAppRoutes.transfer_to_friend} element={<TransferToFriend />} />
                    <Route path={AllAppRoutes.transfer_between_accounts} element={<TransferBetweenAccounts />} />
                    <Route path={AllAppRoutes.withdraw_to_bank} element={<WithdrawToBank />} />

                    <Route path="/profile/sales-report" element={<SalesReportPage />} />
                    <Route path={AllAppRoutes.e_id} element={<E_IDPage />} />
                    <Route path={AllAppRoutes.assessment} element={<AssessmentPage />} />

                    <Route path={AllAppRoutes.fund_user_wallet} element={<FundWalletPage />} />
                    <Route path={AllAppRoutes.start_earning} element={<StartEarningPage />} />
                    <Route path={AllAppRoutes.wise9ja_history} element={<Wise9jaHistoryPage />} />
                    <Route path={AllAppRoutes.wallet_history} element={<WalletHistoryPage />} />
                    <Route path={AllAppRoutes.single_history} element={<SingleHistoryPage />} />
                    <Route path={AllAppRoutes.how_referral_works} element={<HowReferralWorksPage />} />
                    <Route path={AllAppRoutes.profile_referral} element={<ReferralPage />} />

                    <Route path={AllAppRoutes.wallet} element={<WalletPage />} />
                    <Route path="/checkout" element={<CheckOutPage />} />

                </Routes>
            </BrowserRouter>
        </Provider>
    )
}

export default AppRoutes;