import React, { useEffect, useState } from 'react';
import { Tabs, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { _single_restaurant_menu, _get_categories } from '../../common/axios_services';
import { FoodCard } from './FoodCard';

const { TabPane } = Tabs;

// Content Component for each tab
const TabContent = ({ items }) => (
    <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-y-16 gap-6 mb-36">
      {items.map((item, index) => (
          <FoodCard key={index} item={item} />
      ))}
    </div>
);

export const ImageTray = () => {
  const { id: restaurantId } = useParams();
  const [menu, setMenu] = useState({});
  const [loading, setLoading] = useState(true);
  const [restaurantProducts, setRestaurantProducts] = useState([]);
  const [categoryTags, setCategoryTags] = useState([]);

  const navigate = useNavigate();

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: '',
      description: message,
    });
  };

  // Get Categories
  const getCategories = async () => {
    try {
      setLoading(true);
      const categories = await _get_categories();
      setCategoryTags(categories.data.data);
    } catch (err) {
      if (err.response) {
        if (err.response.data.message === 'Unauthorized') {
          localStorage.removeItem('konnect_token');
          navigate('/signin');
        }
        openNotificationWithIcon('error', err.response.data.message);
      } else {
        openNotificationWithIcon('error', err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  // Fetch menu for the restaurant
  const fetchMenu = async (id) => {
    setLoading(true);
    try {
      const response = await _single_restaurant_menu({
        page: 1,
        per_page: 500,
        id,
      });

      const menuData = response?.data?.data || [];
      const categorizedData = {
        All: menuData, // Default "All" tab
      };

      // Dynamically categorize menu items based on categoryTags
      categoryTags.forEach((category) => {
        categorizedData[category.name] = menuData.filter((item) =>
            item.category?.some((cat) => cat.category_id === category.id)
        );
      });

      setMenu(categorizedData);
      setRestaurantProducts(menuData); // Save raw product data if needed
    } catch (err) {
      if (err?.response?.data?.message) {
        if (err?.code === 'ERR_NETWORK') {
          openNotificationWithIcon(
              'error',
              'An error occurred while fetching product data. Please check network and try again'
          );
        } else {
          openNotificationWithIcon('error', err?.response?.data?.message);
        }
      } else {
        openNotificationWithIcon(
            'error',
            'An error occurred while fetching product data. Please reload page to try again'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (restaurantId) {
      fetchMenu(restaurantId); // Fetch menu when restaurantId is available
    }
  }, [restaurantId, categoryTags]);

  useEffect(() => {
    getCategories();
  }, []);

  if (loading) return <p>Loading menu...</p>;
  if (!menu.All || menu.All.length === 0) return <p>No menu available.</p>;

  return (
      <Tabs defaultActiveKey="All">
        {Object.keys(menu).map((key) => (
            <TabPane tab={key} key={key}>
              <TabContent items={menu[key]} />
            </TabPane>
        ))}
      </Tabs>
  );
};
