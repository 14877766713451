import { LocationWrapper } from "../../components/LocationWrapper";
import {Col, Drawer, Modal, notification, Row, Select} from "antd";
import { RestaurantCardDeals } from "../../components/RestaurantCardDeals";
import { FoodImageArea } from "../../components/FoodImageArea";
import FooterDisplay from "../../components/footer";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {_get_cities_by_state_code, _restaurants_in_lga} from "../../common/axios_services";
import DiscountModal from "../../components/DiscountModal";




const Restaurant = () => {
  const [all_lgas, set_all_lgas] = useState([]);
  const [selectedLGA, setSelectedLGA] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [restaurants, setRestaurants] = useState([]); // State for restaurants
  const location = useLocation();
  const Navigate = useNavigate();
  const [isDiscountModalVisible, setIsDiscountModalVisible] = useState(false);

  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      description: message,
    });
  };


  // Fetch LGAs for Lagos using the API
  const fetchCities = async (state_code = "LA", keyword = "") => {
    try {
      const uploadedCities = await _get_cities_by_state_code(state_code, keyword);
      set_all_lgas(uploadedCities.data.data);
    }catch (err) {
      if (err.response) {
        if (err.response.data.message === "Unauthorized") {
          localStorage.removeItem("konnect_token");
          Navigate("/");
        }
        openNotificationWithIcon("error", err.response.data.message);
        set_all_lgas([]);
      } else {
        openNotificationWithIcon("Something went wrong", err.message);
      }
      set_all_lgas([]);
    }
  };

  const fetchRestaurants = async (lga) => {
    try {
        const response = await _restaurants_in_lga({
            page: 1,
            per_page: 1000,
            state: "Lagos",
            lga: lga,
        });
        const lgaParts = lga.split("&").map((part) => part.trim().toLowerCase());
        let filteredRestaurants = response.data.data.filter((restaurant) => {
            return (
                restaurant.city &&
                lgaParts.some((lgaPart) => restaurant.city.toLowerCase().includes(lgaPart)) &&
                restaurant.status === "approved" // Ensure only approved restaurants are included
            );
        });
        // Check if no restaurants are found
        if (filteredRestaurants.length === 0) {
            setRestaurants([]); // Set state to an empty array
        } else {
            setRestaurants(filteredRestaurants); // Update the restaurants state
        }
        return filteredRestaurants;
    } catch (err) {
        if (err.response) {
            if (err.response.data.message === "Unauthorized") {
                localStorage.removeItem("konnect_token");
                Navigate("/");
            }
            openNotificationWithIcon("error", err.response.data.message);
        } else {
            openNotificationWithIcon("Something went wrong", err.message);
        }
        setRestaurants([]); // Clear restaurants in case of an error
        return [];
    }
};




  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const lgaFromQuery = params.get("lga");

    const initializeData = async () => {
      if (lgaFromQuery) {
        setSelectedLGA(lgaFromQuery);
        localStorage.setItem("selectedLGA", lgaFromQuery);
        const filteredRestaurants = await fetchRestaurants(lgaFromQuery);
        if (filteredRestaurants.length === 0) {
          setRestaurants([]); // If no data, ensure it's empty
          openNotificationWithIcon('info', 'No restaurants available for this location.');
        }
      } else {
        setIsModalVisible(true);
        setRestaurants([]); // No location selected
      }

      await fetchCities(); // Fetch available LGAs
    };

    initializeData();
  }, [location.search]);

  const handleLGAChange = async (value) => {
    setSelectedLGA(value); // Update selected LGA
    localStorage.setItem("selectedLGA", value);
    const filteredRestaurants = await fetchRestaurants(value); // Fetch restaurants for the selected LGA
    setRestaurants(filteredRestaurants); // Update the restaurants state

    // Close the drawer after selection
    if (value) {
      setIsModalVisible(false);
      setIsDiscountModalVisible(true);
    }
  };





  return (
    <>
      <div style={{ backgroundColor: '#F2F2F2' }}>
        <LocationWrapper
            selectLoc={true}
            selectedLGA={selectedLGA}
            handleLGAChange={handleLGAChange}
            lagosLga={all_lgas}
        />
        {/* header Banner */}
        <div className="headerBg w-full mt-2 ">
          <Row
          align={"middle"}
          className="h-full w-full px-12 py-36 "
          >
            <Col xs={24} md={12}>
                <div className=" max-md:text-center flex items-center text_h3 ">
                  <p className="md:text-8xl xl:text-9xl text-8xl text_h3 text-white ">
                    Restaurants
                  </p>
                </div>
            </Col>
          </Row>
        </div>
        {/* section 3 */}
        <RestaurantCardDeals />

        {/* BANNER */}
        {/* <Row justify={"center"} className="w-full h-full  ">
          <Col xs={24} lg={22} xl={23}>
            <div className="restaurantBanner w-full">
            </div>
          </Col>
        </Row> */}

        {/* Food Image Area */}
        <div className="mb-24">
          <Row justify={"center"}>
            <Col xs={22} lg={23}>
              <div className="w-full">
                <FoodImageArea
                    restaurants={restaurants}
                />
              </div>
            </Col>
          </Row>
        </div>

        <FooterDisplay/>

        <Drawer
            title="Select Your Location"
            footer={null}
            onClose={() => setIsModalVisible(false)} // Allow manual closure
            open={isModalVisible}
            placement="bottom"
            className="partnerModal"
        >
          <div>
            <h2 className="text-green-800 font-bold flex items-center my-4 mt-8 text-5xl ">
              Select Your Location
            </h2>
            <div className="mt-8">
              <Select
                  value={selectedLGA}
                  onChange={handleLGAChange}
                  className="w-full rest_searchDrawer"
                  placeholder="Select Location"
                  disabled={all_lgas.length === 0} // Disable if no LGAs are available
              >
                {all_lgas.length === 0 ? (
                    <Select.Option value="" disabled>
                      Select Location
                    </Select.Option>
                ) : (
                    all_lgas.map((lga) => (
                        <Select.Option key={lga.id} value={lga.city_name}>
                          {lga.city_name}
                        </Select.Option>
                    ))
                )}
              </Select>
            </div>
          </div>
        </Drawer>

        {/*<DiscountModal*/}
        {/*    isVisible={isDiscountModalVisible}*/}
        {/*    onClose={() => setIsDiscountModalVisible(false)}*/}
        {/*/>*/}


      </div>
    </>
  )
}

export default Restaurant;