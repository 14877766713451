
import {Card, Col, notification, Row, Switch, Radio, Spin, Modal, Drawer} from 'antd';
import React, { useEffect, useState } from 'react';
import map from '../../assets/images/restaurant/Map_of_Assyria.png';
import map_locationIcon from '../../assets/images/restaurant/map_locationIcon.svg';
import info_circle from '../../assets/images/restaurant/-Info-circle.svg';
import {useNavigate, useParams} from 'react-router-dom';
import { CheckoutDrawer } from '../../components/restaurant/CheckoutDrawer';
import FooterDisplay from '../../components/footer';
import { ReactComponent as Cancel } from '../../assets/images/icons/x.svg';
import {
    _confirm_user_transfer_pin,
    _get_saved_address,
    _get_user_wallet,
    _single_restaurant,
    _calculate_restaurant_order_price,
    _placeMenuOrder
} from "../../common/axios_services";
import {useDispatch, useSelector} from "react-redux";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import NumberFormat from "react-number-format";
import {fetchData, profileData} from "../../slices/profileSlice";
import { setRestaurant } from "../../slices/restaurantCartSlice";
import TopUpWallet from "./TopUpWallet";
import {LocationWrapper} from "../../components/LocationWrapper";
import RequestTransactionCode from "../../components/profile/transcode";
import BackToRestaurant from "./BackToRestaurant";






const ReviewCheckout = () => {
    const navigate = useNavigate()
    const [wantsCutlery, setWantsCutlery] = useState(false);
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const [allDeliveryLocations, setAllDeliveryLocations] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [deliveryMethod, setDeliveryMethod] = useState('standard');
    const [loading, setLoading] = useState(true);
    const [restaurantData, setRestaurantData] = useState({});
    const {id: restaurantId} = useParams();
    const [userTransferData, setUserTransferData] = useState({});
    const restaurantCarts = useSelector(store => store.restaurantCart.items);
    const dispatch = useDispatch();
    const [deliveryMode, setDeliveryMode] = useState("pickup");
    const [selectedState, setSelectedState] = useState("");
    const [selectedLGA, setSelectedLGA] = useState("");
    const [completeOrderEnabled, setCompleteOrderEnabled] = useState(false);
    const [displayTransCodeModal, setDisplayTransCodeModal] = useState(false);
    const [userPin, setUserPin] = useState("");
    const [otpCounter, setOtpCounter] = useState(false);
    const [calculatedOrderPrice, setCalculatedOrderPrice] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);


    const antIcon = <LoadingOutlined style={{fontSize: 24}} spin/>;
    const showDrawer = () => setVisibleDrawer(true);
    const closeDrawer = () => setVisibleDrawer(false);


    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
        });
    };

    const handleModalOk = () => {
        setIsModalVisible(false);
        // Reset to pickup if the user tried selecting delivery
        setDeliveryMode("pickup");
    };


    // Fetch restaurant info
    const fetchRestaurantInfo = async (id) => {
        try {
            const response = await _single_restaurant({id});
            setRestaurantData(response.data.data);
            setLoading(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem("konnect_token");
                    navigate("/");
                }
                openNotificationWithIcon("error", err.response.data.message);
            } else {
                openNotificationWithIcon("Something went wrong", err.message);
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (restaurantId) {
            fetchRestaurantInfo(restaurantId);
            // Ensure the cart is hydrated on page load
            dispatch(setRestaurant({ restaurantId }));
        }
    }, [restaurantId, dispatch]);

    // NE THINGS 23/12
    useEffect(() => {
        // Restore the cart state when the component mounts
        dispatch(setRestaurant({ restaurantId }));
    }, [dispatch, restaurantId]);

    useEffect(() => {
        // Only calculate the price if the cart has been restored and contains items
        if (restaurantCarts.length > 0) {
            calculateMenuPrice();
        }
    }, [restaurantCarts]);


    // OLD

    const fetchUserAddresses = async () => {
        try {
            const allAddresses = await _get_saved_address();
            setAllDeliveryLocations(allAddresses.data.data);
        } catch (err) {
            notification.error({
                message: "Error",
                description: err.response?.data?.message || "Something went wrong.",
            });
        }
    };

    const fetchUserWallet = async () => {
        try {
            const user_profile = await _get_user_wallet();
            let userData = user_profile?.data.data;
            setUserTransferData(userData);
            setLoading(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate(`/signin`)
                }
                dispatch(profileData(false))
                dispatch(fetchData(false))
            } else {
            }
        }
    }

    const getFormattedOrderData = () => {
        const menuItems = restaurantCarts.map((item) => ({
            id: item?.productId || "", // Use productId for menu.id
            quantity: item?.quantity || 0, // Default to 0 if quantity is undefined
        }));

        const extras = restaurantCarts
            .filter((item) => item.extras?.length) // Only include items with extras
            .flatMap((item) =>
                item.extras.map((extra) => ({
                    id: extra?.id || "", // Use the extra id
                    quantity: extra?.quantity || 0, // Default to 0 if quantity is undefined
                }))
            );

        return {
            restaurant_id: restaurantId,
            payment_method: "wallet",
            delivery_type: deliveryMode === "delivery" ? "standard" : "pickup",
            cutlery: wantsCutlery,
            app: false,
            delivery_address_id: deliveryMode === "delivery" ? selectedAddress : "",
            menu: menuItems,
            extra: extras,
        };
    };



    const calculateMenuPrice = async () => {
        try {
            setLoading(true);

            // Get the formatted order data
            const data = getFormattedOrderData();

            // Call the API with the formatted data
            const calculatedResult = await _calculate_restaurant_order_price(data);

            // openNotificationWithIcon(
            //     "success",
            //     calculatedResult?.data?.title || "Calculation Successful",
            //     calculatedResult?.data?.message || "Price calculated successfully."
            // );

            setCalculatedOrderPrice(calculatedResult?.data?.data || {});
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon("error", err.response.data.message || "Error occurred");
            } else {
                openNotificationWithIcon("error", "Error", err.message || "Something went wrong");
            }
        } finally {
            setLoading(false);
        }
    };

    // HandleMenuOrder
    const handleMenuOrder = async () => {
        try {
            setLoading(true);
            const orderData = getFormattedOrderData();
            const orderResult = await _placeMenuOrder(orderData); // Replace with your API function
            openNotificationWithIcon(
                "success",
                orderResult?.data?.title || "Order Successful",
                orderResult?.data?.message || "Your order has been placed successfully."
            );
            // Close any active drawers/modal
            toggleTransCodeModalDisplay();
            // Clear the localStorage for this restaurant's cart
            if (restaurantId) {
                localStorage.removeItem(`restaurantCart_${restaurantId}`);
                // Reset the cart state in Redux
                dispatch(setRestaurant({ restaurantId: null }));
            }

            // Navigate to the checkout success page
            const orderId = orderResult?.data?.data?.order?.order.id;
            navigate(`/restaurant/${orderId}/checkout_successful`);
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon("error", err.response.data.message || "Order failed");
            } else {
                openNotificationWithIcon("error", "Error", err.message || "Something went wrong");
            }
        } finally {
            setLoading(false);
        }
    };




    // const calculateMenuPrice = async (values) => {
    //     try {
    //         setLoading(true);
    //         const data = {
    //             restaurant_id: restaurantId,
    //             payment_method: "wallet",
    //             delivery_type: deliveryMode,
    //             cutlery: true,
    //             app: false,
    //             delivery_address_id: "",
    //             menu: [
    //                 {
    //                     "id": values.menuId || "",
    //                     "quantity": values.menuQuantity || '0'
    //                 }
    //             ],
    //             extra: [
    //                 {
    //                     "id": values.extraId || "",
    //                     "quantity": values.extraQuantity || '0'
    //                 }
    //             ]
    //         }
    //         const calculatedResult = await _calculate_restaurant_order_price(data);
    //         openNotificationWithIcon('success', calculatedResult?.data?.title, calculatedResult?.data?.message);
    //
    //     } catch (err) {
    //         if (err.response) {
    //             if (err.response.data.message === "Unauthorized") {
    //                 localStorage.removeItem('konnect_token')
    //                 navigate(`/signin`)
    //             }
    //             openNotificationWithIcon("error", err.response.data.message)
    //
    //         } else {
    //             openNotificationWithIcon("Something went wrong", err.message);
    //         }
    //     }
    // }

    console.log("I am this addresss", selectedAddress);

    const handleAddressChange = (addressId) => {
        setSelectedAddress(addressId); // Update the selected address state
        console.log("I am this addresss", addressId);

        // Use the provided addressId directly to calculate the price
        if (addressId) {
            calculateMenuPrice(addressId);
        }
    };


    useEffect(() => {
        fetchUserWallet()
        fetchUserAddresses();
    }, []);

    useEffect(() => {
        calculateMenuPrice();
    }, [deliveryMode, selectedAddress ]);

    // Calculate total items and total cost
    const totalItems = restaurantCarts.reduce((total, item) => total + item.quantity, 0);

    const totalAmount = restaurantCarts.reduce((total, item) => {
        const price = item?.detail?.public_price || 0; // Use 0 if price is invalid
        const discount = item?.detail?.discount || 0;  // Use 0 if discount is missing or invalid
        // const discountedPrice = discount > 0
        //     ? price * (1 - discount / 100) // Apply discount
        //     : price; // No discount, use the original price
        let itemTotal = price * (item.quantity || 0); // Ensure quantity is valid (default to 0)
        const extrasTotal = (item.extras || []).reduce((extraTotal, extra) => {
            const extraPrice = parseFloat(extra?.price) || 0; // Ensure price is valid
            const extraQuantity = extra?.quantity || 0; // Ensure quantity is valid
            return extraTotal + (extraPrice * extraQuantity); // Add the extra's total
        }, 0);
        itemTotal += extrasTotal;
        return total + itemTotal; // Accumulate the total amount
    }, 0);


    const handleDeliveryModeChange = (e) => {
        const selectedMode = e.target.value;
        if (selectedMode === "delivery") {
            // Show the modal
            setIsModalVisible(true);
        } else {
            // Allow switching to pickup
            setDeliveryMode(selectedMode);
        }
    };

   // Validation Logic
    const validateForm = () => {
        // Ensure critical data is available
        if (
            userTransferData?.available_balance === undefined ||
            calculatedOrderPrice?.finalTotal === undefined
        ) {
            return false; // Disable button if data is not fully loaded
        }

        // Check conditions for enabling the button
        const isBalanceSufficient =
            userTransferData.available_balance >= calculatedOrderPrice.finalTotal;

        if (deliveryMode === "delivery") {
            return (
                selectedAddress !== null &&
                deliveryMethod !== null &&
                isBalanceSufficient
            );
        }

        if (deliveryMode === "pickup") {
            return isBalanceSufficient;
        }

        return false; // Default to invalid
    };

    // Enable/Disable Complete Order Button
    React.useEffect(() => {
        const isFormValid = validateForm();
        setCompleteOrderEnabled(isFormValid);
    }, [
        deliveryMode,
        selectedAddress,
        deliveryMethod,
        userTransferData?.available_balance,
        calculatedOrderPrice?.finalTotal,
    ]);


    // Transaction Pin

    const toggleTransCodeModalDisplay = () => {
        if (!validateForm()) {
            alert("Please complete all required fields before proceeding.");
            return;
        }
        // // Open the drawer or perform the necessary action
        setDisplayTransCodeModal(!displayTransCodeModal);
        setOtpCounter(true);
        console.log("Complete Order");
    };

    const verifyUserPin = async (e) => {
        setLoading(true);
        setDisplayTransCodeModal(false);
        try {
            const profile = await _confirm_user_transfer_pin(e);

            // If PIN is verified, proceed with placing the order
            await handleMenuOrder()

            setLoading(false);
        } catch (err) {
            if (err?.response?.data?.message) {
                if (err?.code === "ERR_NETWORK") {
                    openNotificationWithIcon(
                        "error",
                        "An error occurred while checking pin. Please check network and try again"
                    );
                } else if (err?.response?.data?.message === "Unauthorized") {
                    openNotificationWithIcon(
                        "error",
                        "User Authorization expired. Please log in to continue"
                    );
                    navigate("/signin");
                } else {
                    openNotificationWithIcon(
                        "error",
                        err?.data?.message.length
                            ? err?.data?.message
                            : err?.response?.data?.message
                    );
                }
            } else {
                openNotificationWithIcon(
                    "error",
                    "An error occurred while checking pin. Please try again"
                );
            }
            setLoading(false);
        }
    };




        return (
        <Spin spinning={loading} indicator={antIcon}>
            <div className="review-checkout-container">
                <div className="header px-10 py-8">
                    <Row justify="center">
                        <Col xs={24}>
                            <BackToRestaurant restaurantId={restaurantId} />
                            <h2 className="lg:text-6xl text-4xl">
                                Review your order from {restaurantData.name}
                            </h2>
                        </Col>
                    </Row>
                </div>
                <div className="content px-10">
                    <Row justify="center">
                        {/* Left Column */}
                        <Col xs={24} lg={16}>
                            <div className={"flex flex-col gap-4"}>
                                {/* Payment Section */}
                                <div className="payment-section">
                                    <h2 className="text-2xl">Payment</h2>
                                    <Card className="mt-3 border-2 rounded-2xl">
                                        <Radio.Group
                                            value="wallet"
                                            className="w-full"
                                            disabled
                                        >
                                            <div className="flex justify-between items-center">
                                                <Radio value="wallet" className="text-3xl">
                                                    <div className="flex flex-col">
                                                        <p className="text-2xl text-black">Wallet</p>
                                                    </div>
                                                </Radio>
                                                <div className="text-right">
                                                    <p className="text-2xl font-semibold">
                                                        {!loading
                                                            ? (
                                                                <NumberFormat
                                                                    value={+userTransferData?.available_balance}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    prefix={'₦'}
                                                                    className="numeric"
                                                                />
                                                            )
                                                            : "-"
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </Radio.Group>
                                    </Card>
                                </div>
                                {/*PAYMENT PROCESSING*/}
                                <TopUpWallet userTransferData={userTransferData}/>

                                {/* MODE OF DELIVERY Section */}
                                <div className="delivery-section my-5">
                                    <h2 className="text-2xl">Delivery Mode</h2>
                                    <Card className="mt-3 border-2 rounded-2xl">
                                        <div className="divide-y-2">
                                            <Radio.Group
                                                // onChange={(e) => setDeliveryMode(e.target.value)}
                                                onChange={handleDeliveryModeChange}
                                                value={deliveryMode}
                                                className="w-full divide-y-2 flex flex-col gap-y-5"
                                            >
                                                <div className="flex justify-between items-center py-3">
                                                    <Radio value="pickup">
                                                        <div className="flex flex-col">
                                                            <p className="text-2xl">Pickup</p>
                                                        </div>
                                                    </Radio>
                                                </div>
                                                <div className="flex justify-between items-center py-3">
                                                    <Radio value="delivery">
                                                        <div className="flex flex-col">
                                                            <p className="text-2xl">Delivery</p>
                                                        </div>
                                                    </Radio>
                                                </div>
                                            </Radio.Group>
                                        </div>
                                    </Card>
                                </div>
                                {/* Conditional Delivery Sections */}
                                {deliveryMode === "Delivery" && (
                                    <>
                                        {/* Delivery Address */}
                                        <div className="delivery-address-section my-5">
                                            <div className="flex justify-between">
                                                <h2 className="text-2xl">Delivery Address</h2>
                                                {allDeliveryLocations.length > 0 && (
                                                    <span
                                                        className="text-green-600 font-bold text-2xl cursor-pointer"
                                                        onClick={showDrawer}
                                                    >Change</span>
                                                )}
                                            </div>
                                            {visibleDrawer && (
                                                <CheckoutDrawer
                                                    visible={visibleDrawer}
                                                    onClose={closeDrawer}
                                                    title={
                                                        allDeliveryLocations.length > 0
                                                            ? "Change"
                                                            : "Add"
                                                    }
                                                    selectedState={selectedState}
                                                    selectedLGA={selectedLGA}
                                                />
                                            )}
                                            <Card className="mt-3 border-2 rounded-2xl">
                                                <div className="w-full my-5 mb-8">
                                                    <img src={map} className="h-72 w-full" alt="Location map"/>
                                                </div>
                                                {allDeliveryLocations.length > 0 ? (
                                                    allDeliveryLocations.map((item, index) => (
                                                        <div key={index}>
                                                            <div
                                                                className="location-info flex flex-wrap gap-3 text-black mt-3">
                                                                <h2 className="location-name">{item.name}</h2>
                                                                <p className="location-contact">{item.phone_contact}</p>
                                                                <p className="location-contact">{item.email}</p>
                                                            </div>
                                                            <div
                                                                className="location-address flex items-center gap-3 text-black mt-3">
                                                        <span className="address-icon">
                                                            <img src={map_locationIcon} alt="Location Icon"
                                                                 className="w-6 h-6"/>
                                                        </span>
                                                                <p className="address-text text-2xl font-bold">
                                                                    {[
                                                                        item.floor_no,
                                                                        item.landmark,
                                                                        item.lga,
                                                                        item.city,
                                                                        item.state,
                                                                    ].filter(Boolean).join(", ")}
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center gap-3 mt-3">
                                                                <input
                                                                    type="radio"
                                                                    name="selectedAddress"
                                                                    value={index}
                                                                    checked={selectedAddress === index}
                                                                    onChange={() => setSelectedAddress(index)}
                                                                    className="mr-2"
                                                                />
                                                                <label htmlFor={`address-${index}`} className="text-xl">
                                                                    Use this address
                                                                </label>
                                                            </div>
                                                            <hr className="my-5" style={{backgroundColor: "green"}}/>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <span
                                                        className="text-[#44843F] cursor-pointer mt-24 font-bold text-2xl"
                                                        onClick={showDrawer}
                                                    >
                                                      <PlusOutlined/>  Add Delivery Address
                                                    </span>
                                                )}
                                            </Card>
                                        </div>

                                        {/* Delivery Method */}
                                        <div className="delivery-section my-5">
                                            <h2 className="text-2xl">Delivery Fee</h2>
                                            <Card className="mt-3 border-2 rounded-2xl">
                                                <div className="divide-y-2">
                                                    <Radio.Group
                                                        onChange={(e) => setDeliveryMethod(e.target.value)}
                                                        value={deliveryMethod}
                                                        className="w-full divide-y-2 flex flex-col gap-y-5"
                                                    >
                                                        <div className="flex justify-between items-center py-3">
                                                            <Radio value="standard">
                                                                <div className="flex flex-col">
                                                                    <p className="text-2xl">Standard Delivery</p>
                                                                </div>
                                                            </Radio>
                                                            <p className="text-xl font-semibold">₦400</p>
                                                        </div>
                                                    </Radio.Group>
                                                </div>
                                            </Card>
                                        </div>
                                    </>
                                )}

                                {deliveryMode === "pickup" && (
                                    <>
                                        <div className="delivery-address-section my-5">
                                            <h2 className="text-2xl">Pickup Address</h2>
                                            <Card className="mt-3 border-2 rounded-2xl">
                                                <h2 className="text-[#44843F] text-3xl">{restaurantData.name}</h2>
                                                <p className="text-2xl font-bold">
                                                    {
                                                        `${restaurantData.address}, ${restaurantData.city}  ${restaurantData.state} `
                                                    }
                                                    {/*{restaurantData.address + restaurantData.city + restaurantData.state || "No address available"}*/}
                                                </p>
                                            </Card>
                                        </div>
                                        <div>
                                            <p className={'text-[red] text-center font-bold my-5'}>Note, you will be
                                                debited if you fail to pickup within hours.</p>
                                        </div>

                                    </>

                                )}

                                {/* Complete Order Button */}
                                <button
                                    onClick={toggleTransCodeModalDisplay}
                                    disabled={!completeOrderEnabled}
                                    className={`${
                                        completeOrderEnabled ? "bg-[#44843F]" : "bg-gray-400"
                                    } text-white flex justify-center w-full rounded-3xl px-10 my-10 py-3`}
                                >
                                    <p>
                                        {
                                            completeOrderEnabled ? "Complete Order" : "Insufficient Balance"
                                        }
                                    </p>
                                </button>

                                {/*/!*COMPLETE ORDER DRAWER*!/*/}
                                {/*<Drawer*/}
                                {/*    open={displayTransCodeModal}*/}
                                {/*    onClose={toggleTransCodeModalDisplay}*/}
                                {/*    width={820}*/}
                                {/*    height="90%"*/}
                                {/*    placement="bottom"*/}
                                {/*>*/}
                                {/*    <div className="px-10 mt-4">*/}
                                {/*        <div className="drawer_header py-3 flex justify-between">*/}
                                {/*            <h2 className="text-4xl"> {"Complete Order"} </h2>*/}
                                {/*            <Cancel onClick={toggleTransCodeModalDisplay}*/}
                                {/*                    className="cancel cursor-pointer"/>*/}
                                {/*        </div>*/}
                                {/*        <hr className={"mb-5"}/>*/}
                                {/*        <span className={"text-2xl "}>*/}
                                {/*           {calculatedOrderPrice && restaurantData?.name && (*/}
                                {/*               <>*/}
                                {/*                   Please click the Order button to authorize the payment of&nbsp;*/}
                                {/*                   <NumberFormat*/}
                                {/*                       value={calculatedOrderPrice.finalTotal || 0}*/}
                                {/*                       displayType={"text"}*/}
                                {/*                       thousandSeparator={true}*/}
                                {/*                       decimalScale={2}*/}
                                {/*                       fixedDecimalScale={true}*/}
                                {/*                       prefix={"₦"}*/}
                                {/*                   />{' '}*/}
                                {/*                   to <strong>{restaurantData.name}</strong> {' '} for:*/}
                                {/*               </>*/}
                                {/*           )}*/}
                                {/*        </span>*/}
                                {/*        <div>*/}
                                {/*            {restaurantCarts.map((item, index) => {*/}
                                {/*                const extrasIncluded = item.extras && item.extras.length > 0 ? " (extras included)" : "";*/}
                                {/*                return (*/}
                                {/*                    <div className="flex gap-2" key={index}>*/}
                                {/*                        <ul className={"list-disc px-8 marker:text-green-800"}>*/}
                                {/*                            <li>*/}
                                {/*                                {item.quantity} pack(s) of {item.detail?.product_name}*/}
                                {/*                                {extrasIncluded}*/}
                                {/*                            </li>*/}
                                {/*                        </ul>*/}
                                {/*                    </div>*/}
                                {/*                );*/}
                                {/*            })}*/}
                                {/*        </div>*/}
                                {/*        <div className={"flex items-center justify-center mt-5"}>*/}
                                {/*            <button*/}
                                {/*                onClick={handleMenuOrder}*/}
                                {/*                disabled={loading} // Disable button while loading*/}
                                {/*                className={`py-3 px-5 rounded-full text-2xl flex items-center justify-center text-white ${*/}
                                {/*                    loading ? "bg-gray-400 cursor-not-allowed" : "bg-green-600"*/}
                                {/*                }`}*/}
                                {/*            >*/}
                                {/*                {loading ? (*/}
                                {/*                    <>*/}
                                {/*                        <Spin size="small" className="mr-2"/> /!* Spinner *!/*/}
                                {/*                        Processing...*/}
                                {/*                    </>*/}
                                {/*                ) : (*/}
                                {/*                    "Order Now"*/}
                                {/*                )}*/}
                                {/*            </button>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</Drawer>*/}
                                {/*Transaction Pin*/}
                                <RequestTransactionCode
                                    openDrawer={displayTransCodeModal}
                                    closeDrawer={toggleTransCodeModalDisplay}
                                    inputCount={4}
                                    actionFunction={verifyUserPin}
                                    stateFunc={setUserPin}
                                    transChildrenPresent = {true}
                                >
                                    <div className="px-10 mt-4">
                                        <div className="drawer_header py-3 flex justify-between">
                                            <h2 className="text-4xl"> {"Complete Order"} </h2>
                                            <Cancel onClick={toggleTransCodeModalDisplay}
                                                    className="cancel cursor-pointer"/>
                                        </div>
                                        <hr className={"mb-5"}/>
                                        <span className={"text-2xl "}>
                                             {calculatedOrderPrice && restaurantData?.name && (
                                                   <>
                                                       Please click the Order button to authorize the payment of&nbsp;
                                                       <NumberFormat
                                                           value={calculatedOrderPrice.finalTotal || 0}
                                                           displayType={"text"}
                                                           thousandSeparator={true}
                                                           decimalScale={2}
                                                           fixedDecimalScale={true}
                                                           prefix={"₦"}
                                                       />{' '}
                                                       to <strong>{restaurantData.name}</strong> {' '} for:
                                                   </>
                                             )}
                                        </span>
                                        <div>
                                            {restaurantCarts.map((item, index) => {
                                                const extrasIncluded = item.extras && item.extras.length > 0 ? " (extras included)" : "";
                                                return (
                                                    <div className="flex gap-2" key={index}>
                                                        <ul className={"list-disc px-8 marker:text-green-800"}>
                                                            <li>
                                                                {item.quantity} pack(s) of {item.detail?.product_name}
                                                                {extrasIncluded}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </RequestTransactionCode>
                            </div>
                        </Col>

                        {/* Right Column */}
                        {/* CHECKOUT DETAILS */}
                        <Col xs={24} lg={8}>
                            <Card bordered={false}
                                  className="flex-col mb-36 mt-10 px-6 py-10 lg:mt-28 items-center bg-[#f5fff5] rounded-[32px] border-2 border-black">
                                <div>
                                    <div className="rest_header">
                                        <h2 className='text-4xl text-black'>Basket</h2>
                                    </div>
                                    <hr className="my-5"/>
                                    <div className="flex items-center gap-4 py-3 my-3  ">
                                        <img src={restaurantData.display_image} alt="restaurant_logo"
                                             className="w-28 h-28 object-cover rounded-full"/>
                                        <div>
                                            <p className="text-2xl text-black font-semibold">
                                                {restaurantData.name} - {restaurantData.city}
                                            </p>
                                            <p className="text-xl text-[#959595]">
                                                {totalItems} Items •
                                                <NumberFormat
                                                    value={totalAmount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    prefix={' ₦'}
                                                />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="order_item my-10">
                                        {
                                            restaurantCarts.map((item, index) => {
                                                const discount = item?.detail?.discount ? parseFloat(item?.detail?.discount) : 0;
                                                const originalPrice = parseFloat(item?.detail?.public_price);
                                                // const discountedPrice = discount > 0
                                                //     ? (originalPrice * (1 - discount / 100)).toFixed(2)
                                                //     : originalPrice.toFixed(2);

                                                return (
                                                    <>
                                                        <div key={index}>
                                                            <div className="flex justify-between gap-2">
                                                                <p className="text-3xl">{item.quantity} x</p>
                                                                <p className="text-3xl text-wrap">{item.detail?.product_name}</p>
                                                                <p className=' text-[#44843F] '>
                                                                    <NumberFormat
                                                                        value={(originalPrice * item.quantity)}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                        prefix={' ₦'}
                                                                    />
                                                                </p>
                                                            </div>
                                                            {/* Extra Menus */}
                                                            {item.extras && item.extras.length > 0 && (
                                                                <div className="mt-3">
                                                                    <p className="text-2xl font-bold">Extras:</p>
                                                                    {item.extras.map((extra, extraIndex) => (
                                                                        <div key={extraIndex}
                                                                             className="flex justify-between text-lg text-gray-600">
                                                                            <p className="text-2xl">{extra.name} (x{extra.quantity})</p>
                                                                            <p className=' text-[#44843F] text-xl '>
                                                                                <NumberFormat
                                                                                    value={extra.price * extra.quantity}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    decimalScale={2}
                                                                                    fixedDecimalScale={true}
                                                                                    prefix={'₦'}
                                                                                />
                                                                            </p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                        {index !== restaurantCarts.length - 1 &&
                                                            <hr className={'my-3'}/>
                                                        }
                                                    </>

                                                );
                                            })
                                        }

                                        {/* Your Selections */}
                                        {deliveryMode === "delivery" && (
                                            <>
                                            </>
                                            // <div
                                            //     className="bg-[#E4E4E4] rounded-3xl p-4 my-5  border border-black ">
                                            //     <div className="divide-[#959595] divide-y">
                                            //         <div className="flex justify-between text-[#000] text-2xl pt-3">
                                            //             <span>Delivery Fee</span>
                                            //             <span className='flex items-center gap-2'>
                                            //                      <img src={info_circle} className='w-10 h-10'
                                            //                           alt=""/>
                                            //                      <p> ₦0</p>
                                            //                 </span>
                                            //         </div>
                                            //     </div>
                                            // </div>
                                        )}
                                        <div className="my-10">
                                            <div className="flex justify-between py-2">
                                                <p className={"text-2xl font-bold"}>Order amount</p>
                                                <p className={'font-bold text-2xl'}>
                                                    {
                                                        calculatedOrderPrice ? (
                                                                <NumberFormat
                                                                    value={calculatedOrderPrice.total || 0}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    prefix={"₦"}
                                                                />
                                                            ) :
                                                            "loading total amount"
                                                    }
                                                </p>
                                            </div>
                                            <div className="flex justify-between py-2">
                                                <p className={"text-2xl font-bold"}>Less discount</p>
                                                <p className={'text-2xl'}>
                                                    {
                                                        calculatedOrderPrice ? (
                                                                <NumberFormat
                                                                    value={calculatedOrderPrice.discount || 0}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    prefix={"₦"}
                                                                />
                                                            ) :
                                                            "loading total amount"
                                                    }
                                                </p>
                                            </div>
                                            {/*<div className="flex justify-between py-2">*/}
                                            {/*    <p className={"text-2xl font-bold"}>Less discount (pickup)</p>*/}
                                            {/*    <p className={'text-2xl'}>*/}
                                            {/*        {*/}
                                            {/*            calculatedOrderPrice ? (*/}
                                            {/*                    <NumberFormat*/}
                                            {/*                        value={0}*/}
                                            {/*                        displayType={"text"}*/}
                                            {/*                        thousandSeparator={true}*/}
                                            {/*                        decimalScale={2}*/}
                                            {/*                        fixedDecimalScale={true}*/}
                                            {/*                        prefix={"₦"}*/}
                                            {/*                    />*/}
                                            {/*                ) :*/}
                                            {/*                "loading total amount"*/}
                                            {/*        }*/}
                                            {/*    </p>*/}
                                            {/*</div>*/}

                                            <div className="flex justify-between py-2">
                                                <p className={"text-2xl font-bold"}>Amount to pay</p>
                                                <p className={'font-bold text-[#44843F]'}>
                                                    {
                                                        calculatedOrderPrice ? (
                                                                <NumberFormat
                                                                    value={calculatedOrderPrice.finalTotal || 0}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    prefix={"₦"}
                                                                />
                                                            ) :
                                                            "loading total amount"
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            {/* Modal */}
            <Modal
                title=" Delivery Unavailable"
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalOk}
                okText="Okay"
                cancelButtonProps={{style: {display: 'none'}}}
            >
                <p>Delivery service will resume soon.</p>
                <p>Collect from the restaurant and enjoy a discount.</p>
            </Modal>
            <FooterDisplay/>

        </Spin>

        );
};

export default ReviewCheckout;
