import { Button, Card } from 'antd';
import React from 'react';
// import restaurant_food from '../assets/images/homepage/restaurant_food.png';
import { useNavigate } from 'react-router-dom';
import oops from "../assets/images/illustrations/oops.jpg"




export const FoodImageArea = ({restaurants, }) => {
    const navigate = useNavigate()
  return (
        // <div className="lg:p-8  ">
      <div className='bg-white pb-36 px-3 py-2 rounded-3xl '>
          <div className="align-center mt-8 px-4 my-5">
              <div>
                  <h1 className='text-5xl text-[#000000D9]'>Just for you! </h1>
                  <p className='font-gilroyMedium'
                     style={{fontSize: '1.5rem', fontWeight: "medium", color: "#959595"}}>Based on your frequent
                      orders</p>
              </div>
          </div>
          <div className="md:hidden px-4">
              {!restaurants || restaurants.length === 0 ? (
                  <div className="text-center">
                      <img src={oops} width={200} className="mx-auto" alt="oops"/>
                      <h3 className="mt-4 text-gray-600">No Restaurant Found in your selected city</h3>
                  </div>
              ) : (
                  <div className="grid grid-cols-2 gap-4">
                      {restaurants.map((restaurant) => (
                          <div
                              key={restaurant.id}
                              className="bg-white p-3 rounded-lg shadow-md cursor-pointer"
                              onClick={() => navigate(`/restaurant/${restaurant.id}`)}
                          >
                              <img
                                  src={restaurant.display_image}
                                  alt={restaurant.name}
                                  className="w-full h-44 object-cover rounded-md transition-transform duration-300 ease-in-out transform hover:scale-105"
                              />
                              <div className="mt-2">
                                  <h3 className="font-bold text-xl truncate">{restaurant.name}</h3>
                                  <div className="flex justify-between items-center mt-1  text-gray-500">
                                      <p>{`${restaurant.min_ready_time} mins`}</p>
                                      <span className="text-yellow-500">⭐ {restaurant.rating || 'N/A'}</span>
                                  </div>
                              </div>
                          </div>
                      ))}
                  </div>
              )}
          </div>


      </div>
      // </div>
  )
}
