const AppRoutes = {


    // < Route path = "/profile/basic" element = {< ProfileBasicSettings />} />
    // < Route path = "/profile/settings" element = {< ProfileBasicSettings />} />
    //     < Route path = "/profile/transfer" element = {< TransferCash />} />
    signin: "/signin",
    signup: "/signup",
    reset_mail: "/reset-mail",
    verify_email: "/verify-email",
    verify_passcode: "/verify-passcode",
    reset_password: "/reset-password",
    how_wise9ja_works: "/how-wise9ja-works",
    reset_pin: "/reset-pin",

    privacy: "/privacy",
    terms: "/terms",
    partner: "/partner",    
    contact: "/contact",
    settings: "/settings",
    home: "/home",
    profile_settings: "/profile/settings",
    transfer_cash: "/profile/transfer",

    basic_profile: "/profile/basic",
    update_picture: "/profile/update-picture",
    update_password: "/profile/password",
    update_transaction_code: "/profile/transaction-code",
    update_login_pin: "/profile/login-pin",
    update_trans_pin: "/profile/trans-pin",
    update_email: "/profile/update-email",
    view_order_history: "/profile/order-history",


    action_status_page: "/confirm",
    fund_user_wallet: "/profile/fund-wallet",
    pay_bills: "/pay-bills",
    successful_signup: "/successful-signup",
    complete_kyc: "/complete-kyc",

    withdraw_to_bank: "/profile/withdraw-to-bank",
    transfer_to_friend: "/profile/transfer-to-friend",
    transfer_between_accounts: "/profile/transfer-between-accounts",

    start_earning: "/profile/start-earning",
    e_id: "/profile/e-id",
    assessment: "/profile/assessment",

    wise9ja_history: "/wise9ja-history",
    wallet_history: "/wallet-history",
    wallet: "/wallet",
    single_history: "/single-history",
    how_referral_works: "/how-referral-works",
    profile_referral: "/profile/referral"
};

export default AppRoutes;